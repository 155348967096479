.Key {
  display: flex;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  /* font-size: 0.875rem; */
  line-height: 1.25rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.KeyUnselected {
  background-color: rgb(226 232 240);
  /* https://tailwindcss.com/docs/background-color */
}

.KeyUnselected:hover {
  background-color: rgb(203 213 225);
}

.KeyUnselected:active {
  background-color: rgb(148 163 184);
}

.KeyAbsent {
  background-color: rgb(148 163 184);
  color: #ffffff;
}

.KeyCorrect {
  background-color: rgb(34 197 94);
  color: #ffffff;
}

.KeyCorrect:hover {
  background-color: rgb(22 163 74);
}

.KeyCorrect:active {
  background-color: rgb(21 128 61);
}

.KeyPresent {
  background-color: rgb(234 179 8);
  color: #ffffff;
}

.KeyPresent:hover {
  background-color: rgb(202 138 4);
}

.KeyPresent:active {
  background-color: rgb(161 98 7);
}

.KeyRed {
  color: rgb(220 38 38);
}

.KeyGreen {
  color: rgb(22 163 74);
}
