.WordlePlay {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 1rem;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
