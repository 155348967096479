.Cell {
  display: flex;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  /* font-size: 1.125rem; */
  line-height: 1.75rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
  border-width: 2px;
  border-style: solid;
}
